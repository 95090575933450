import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  Tooltip,
  TextField,
  Button,
  TextareaAutosize,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
// import RoundPlaceHolder from "../../../../../assets/images/roundPlaceholder.png";
// import Individual1 from "../../../../../assets/images/individual1.png";
// import Individual2 from "../../../../../assets/images/individual2.png";
// import Placeholder from "../../../../../assets/images/placeOrder.png";
import { ReactComponent as Watch } from "src/assets/images/watch.svg";
import { ReactComponent as ShareNews } from "src/assets/images/icons/shareNews.svg";
import { ReactComponent as SaveNews } from "src/assets/images/icons/saveNews.svg";
import { ReactComponent as SavedNews } from "src/assets/images/icons/savedNews.svg";
import { ReactComponent as CommentNews } from "src/assets/images/icons/commentNews.svg";
import { ReactComponent as Like } from "src/assets/images/icons/like.svg";
import { ReactComponent as DisLike } from "src/assets/images/icons/disLike.svg";
import { ReactComponent as Plus } from "../../../../../assets/images/icons/plus.svg";
import { ReactComponent as Minus } from "src/assets/images/icons/minus.svg";
import CommentsAuthor from "src/assets/images/icons/commentAuthor.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import { Link } from "react-router-dom";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import AdBannner from "src/views/component/AdBanner/AdBanner";
import OurPartner from "src/views/component/ourPartners";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import BetweenContent from "../../../../../assets/images/ad-placeholder/sports2.webp";
import AboveOurPartner from "../../../../../assets/images/ad-placeholder/sports5.webp";
import BelowOurPartner from "../../../../../assets/images/ad-placeholder/homebelow-ourpartner.webp";
import BannerIndia from "../../../../../assets/images/ad-placeholder/IPL_Page_Banner_894x218_4.jpg";
import DefaultImg from "../../../../../assets/images/smartb_default.png";
import Author_Placeholder from "../../../../../assets/images/SmartB_author.png";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
import moment from "moment";
import Loader from "../../../../../components/Loader";
import { Tweet } from "react-twitter-widgets";
import { fetchNews } from "src/helpers/store/Actions/NewsRelated";
// import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import DynamicNewsScript from "./DynamicNewsScript";
import { Config, fetchFromStorage } from "src/helpers/context";
import { setApiMessage } from "src/helpers/commonFunctions";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import Facebook from "../../../../../assets/images/social/fb-link.svg";
import Linkedin from "../../../../../assets/images/social/li-link.svg";
import Twitter_X from "../../../../../assets/images/social/x-link.svg";
import Snapchat from "../../../../../assets/images/social/sc-link.svg";
import Copy from "../../../../../assets/images/social/copy-link.svg";
import Messenger from "../../../../../assets/images/social/mess-link.svg";
import Wp from "../../../../../assets/images/social/wp-link.svg";
import Message from "../../../../../assets/images/social/messages.svg";
import CopyToClipboard from "react-copy-to-clipboard";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  FacebookMessengerShareButton,
} from "react-share";
import { toast } from "react-toastify";
import { Pagination } from "@mui/material";
import DialogBox from "src/views/component/UI/dialogBox";
import useAutoSaveStore from "src/helpers/zustand/useAutoSaveStore";
// import NewsMeta from "./newsMeta";

let timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

const IndividualNews = ({ categoryList }) => {
  const params = useParams();
  const advRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { setApiInfo } = useAutoSaveStore();
  const userData = useSelector((state) => state?.reduxData?.SubscripitionData);
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get("token");
  const dispatch = useDispatch();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [AdData, setAdData] = useState([]);
  const [singleNewsData, setSingleNewsData] = useState({});
  const [commentNewsData, setCommentNewsData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [isCommentLoading, setIsCommentLoading] = useState(false);
  const [categoryListData, setCategoryListData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [replyOption, setReplyOption] = useState(false);
  const [leaveComment, setLeaveComment] = useState("");
  const [emailLeaveChecked, setEmailLeaveChecked] = useState(false);
  const [emailChecked, setEmailChecked] = useState(false);
  const [replyCommentId, setReplyCommentId] = useState({
    parentCommentId: null,
    childCommentId: null,
  });
  const [replyComment, setReplyComment] = useState("");
  const [commentCount, setCommentCount] = useState(0);
  const [commentPage, setCommentPage] = useState(1);
  const [expandedReplies, setExpandedReplies] = useState({});
  const [postCommentLoading, setPostCommentLoading] = useState(false);
  const [commentDeleteModal, setCommentDeleteModal] = useState(false);
  const [commentDeleteId, setCommentDeleteId] = useState("");
  const [load, setLoad] = useState(false);

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
    fetchAdsData();
    // fetchSingleNews();
  }, []);

  useEffect(() => {
    fetchSingleNews();
    fetchNewsComments("first", commentPage);
  }, [params?.categoryId, params?.id]);
  // const handleAdsImression = async ids => {
  //   try {
  //     const payload = {
  //       ids: ids
  //     };
  //     const { status, data } = await axiosInstance.post(
  //       `addImpression`,
  //       payload
  //     );
  //     if (status === 200) {
  //     }
  //   } catch (err) {}
  // };

  const fetchAdsData = async () => {
    try {
      const { status, data } = await axiosInstance.get(
        `campaign?page_id=15&type=web&timeZone=${timezone}`,
      );
      if (status === 200) {
        setAdData(data?.result);
        // let ids = [];
        // let listAds = data?.data?.[0]?.pageAdLists?.map(item => {
        //   ids.push(item?.id);
        // });
        // if (ids.length > 0) {
        //   handleAdsImression(ids);
        // }
      }
    } catch (err) {}
  };
  const fetchAds = (position, height, placeholder) => {
    if (AdData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={AdData?.[0]}
          height={height}
          onAdVisible={handleAdVisible}
          position={position}
        />
      );
    } else {
      return (
        <AdBannner placeholder={placeholder} addetails={[]} height={height} />
      );
    }
  };
  const settings1 = {
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    focusOnSelect: true,
    cssEase: "linear",
    touchMove: true,
    draggable: true,
    prevArrow: (
      <Box className="slick-prev">
        <ChevronLeftIcon />
      </Box>
    ),
    nextArrow: (
      <Box className="slick-next">
        <ChevronRightIcon />
      </Box>
    ),
    responsive: [
      {
        breakpoint: 439,
        settings: {
          // autoplay: true,
          // autoplaySpeed: 2500,
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: true,
        },
      },
    ],
  };

  const fetchSingleNews = async () => {
    setLoading(true);

    try {
      let endpoint;
      if (token) {
        endpoint = `v2/news/preview/articles/${params?.id}`;
      } else {
        endpoint = `v2/news/articles/${params?.id}`;
      }

      const response = await axiosInstance.get(endpoint, {
        headers: token ? { Authorization: `Bearer ${token}` } : undefined,
      });

      const { status, data } = response;

      if (status === 200) {
        setSingleNewsData(data?.result);
        dispatch(fetchNews(data?.result));
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const fetchReplyPostData = async (id) => {
    try {
      const response = await axiosInstance.get(`/v2/news/comment/${id}`);
      return response.data?.result;
    } catch (error) {
      console.error("Error fetching reply post data:", error);
      return null;
    }
  };

  const fetchNewsComments = async (first, page) => {
    if (first) {
      setIsCommentLoading(true);
    }
    setCommentPage(page);
    try {
      const response = await axiosInstance.get(
        `/v2/news/commentByNews/${params?.id}?limit=3&page=${page}`,
      );
      const { status, data } = response;
      if (status === 200) {
        const comments = data?.result;

        const updatedComments = await Promise.all(
          comments?.map(async (comment) => {
            if (comment?.replyPost && comment?.replyPost?.length > 0) {
              const updatedReplyPosts = await Promise.all(
                comment?.replyPost?.map(async (reply) => {
                  if (reply.totalComment > 0 && reply?.status !== "deleted") {
                    const newData = await fetchReplyPostData(reply?.id);
                    return newData ? newData : reply;
                  }
                  return reply;
                }),
              );
              return { ...comment, replyPost: updatedReplyPosts };
            }
            return comment;
          }),
        );
        setCommentCount(data?.count);
        setCommentNewsData(updatedComments);
        setIsCommentLoading(false);
      } else {
        setIsCommentLoading(false);
      }
    } catch (error) {
      setIsCommentLoading(false);
    }
  };

  const handlePaginationClick = (event, page) => {
    fetchNewsComments(null, Number(page));
  };
  const pageNumbers = [];

  if (commentCount > 0) {
    for (let i = 1; i <= Math.ceil(commentCount / 3); i++) {
      pageNumbers.push(i);
    }
  }

  const fetchNewsTime = (newsdate) => {
    if (newsdate) {
      const now = moment();
      const givenTime = moment(newsdate);
      const timeDiffMinutes = now.diff(givenTime, "minutes");
      const timeDiffHours = now.diff(givenTime, "hours");
      const timeDiffDays = now.diff(givenTime, "days");
      const timeDiffWeeks = now.diff(givenTime, "weeks");
      if (timeDiffMinutes > 60) {
        if (timeDiffMinutes / 60 > 24) {
          if (timeDiffDays > 7) {
            return `${timeDiffWeeks} ${
              timeDiffWeeks == 1 ? "week" : "weeks"
            } ago`;
          } else {
            return `${timeDiffDays} ${timeDiffDays == 1 ? "day" : "days"} ago`;
          }
        } else {
          return `${timeDiffHours} ${
            timeDiffHours == 1 ? "hour" : "hours"
          } ago`;
        }
      } else {
        if (timeDiffMinutes == 0) {
          return `Just Now`;
        } else {
          return `${timeDiffMinutes} ${
            timeDiffMinutes == 1 ? "minute" : "minutes"
          } ago`;
        }
      }
    } else return "";
  };

  useEffect(() => {
    if (categoryList?.length > 0) {
      setCategoryListData(categoryList);
    }
  }, [categoryList, params?.categoryId]);

  const fetchCategoryName = (id) => {
    let categoryName = categoryListData?.filter((item) => item?.value == id);
    return categoryName?.[0]?.label;
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  const handleNewsSave = async (e, item) => {
    e.stopPropagation();

    if (handleRestrictedUser()) {
      let payload = {
        status: item?.isSaved === 0 ? "active" : "deleted",
      };
      try {
        const { status, data } = await axiosInstance.post(
          `/v2/news/saved/${item?.id}`,
          payload,
        );
        if (status === 200) {
          setApiMessage(
            "success",
            item?.isSaved === 0
              ? "News successfully saved!"
              : "News removed from saved!",
          );

          const updateNews = { ...item, isSaved: item?.isSaved === 0 ? 1 : 0 };
          setSingleNewsData(updateNews);
        } else {
          setApiMessage("error", data?.message);
        }
      } catch (err) {
        setApiMessage("error", err?.response?.data?.message);
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
      setApiInfo({
        api: `/v2/news/saved/${item?.id}`,
        payload: {
          status: item?.isSaved === 0 ? "active" : "deleted",
        },
        method: "post",
      });
    }
  };

  const handleCopy = () => {
    toast.success("Link Copied Successfully", {
      position: "bottom-center",
      autoClose: 3000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "colored",
    });
  };

  useEffect(() => {
    // Load the Facebook SDK
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "708883073941381",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v18.0",
      });
    };

    (function (d, s, id) {
      var js,
        fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s);
      js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    })(document, "script", "facebook-jssdk");
  }, []);

  const shareOnMessenger = (link) => {
    if (window.FB) {
      window.FB.ui({
        method: "send", // share in facebook post and send in facebook messenger
        link: link, // link in facebook messenger and href in facebook post
      });
    } else {
      console.error("Facebook SDK not loaded.");
    }
  };

  const link = Config.siteBaseURL.slice(0, -1) + location?.pathname;

  const handleLeaveCommentChange = (checked) => {
    setEmailLeaveChecked(checked);
  };

  const handleReply = (item, index, obj, objIndex) => {
    if (handleRestrictedUser()) {
      setReplyCommentId({
        parentCommentId: item?.id ? item?.id : null,
        childCommentId: obj?.id ? obj?.id : null,
      });
      setReplyOption(true);
      setReplyComment("");
      setEmailChecked(false);
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };

  const handleChange = (checked) => {
    setEmailChecked(checked);
  };

  const handlePostLeaveComment = async () => {
    if (handleRestrictedUser()) {
      let payload = {
        newsArticleId: params?.id,
        comment: leaveComment,
        isEmailEnabled: emailLeaveChecked,
      };
      try {
        const { status, data } = await axiosInstance.post(
          `/v2/news/comment`,
          payload,
        );
        if (status === 200) {
          setApiMessage("success", data?.message);
          setEmailLeaveChecked(false);
          setLeaveComment("");
          fetchNewsComments(null, commentPage);
        } else {
          setApiMessage("error", data?.message);
        }
      } catch (err) {
        setApiMessage("error", err?.response?.data?.message);
      }
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };

  const handleReplyPostComment = async (commentData, item, obj) => {
    setPostCommentLoading(true);
    let payload = {
      newsArticleId: params?.id,
      comment: replyComment,
      isEmailEnabled: emailChecked,
      parentNewsCommentId: commentData?.id,
      toUserId: userData?.id,
    };

    try {
      const { status, data } = await axiosInstance.post(
        `/v2/news/comment`,
        payload,
      );
      if (status === 200) {
        setApiMessage("success", data?.message);
        setReplyComment("");
        setEmailChecked(false);
        setReplyOption(false);
        fetchNewsComments(null, commentPage);
        setPostCommentLoading(false);
      } else {
        setApiMessage("error", data?.message);
        setPostCommentLoading(false);
      }
    } catch (err) {
      setApiMessage("error", err?.response?.data?.message);
      setPostCommentLoading(false);
    }
  };

  const handleDeleteModal = (comment) => {
    if (handleRestrictedUser()) {
      setCommentDeleteModal(true);
      setCommentDeleteId(comment);
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };

  const handleDeleteModalClose = () => {
    setCommentDeleteModal(false);
    setCommentDeleteId("");
  };

  const handleCommentDelete = async (comment) => {
    try {
      const { status, data } = await axiosInstance.delete(
        `/v2/news/comment/${comment?.id}`,
      );
      if (status === 200) {
        // setApiMessage("success", data?.message);
        handleDeleteModalClose();
        fetchNewsComments(null, commentPage);
      } else {
        setApiMessage("error", data?.message);
      }
    } catch (err) {
      setApiMessage("error", err?.response?.data?.message);
    }
  };

  const handleLikeDisLike = async (comment, item, obj, type) => {
    if (handleRestrictedUser()) {
      let payload = {
        newsCommentId: comment?.id,
        type: type,
      };

      try {
        const { status, data } = await axiosInstance.post(
          `/v2/news/like`,
          payload,
        );
        if (status === 200) {
          if (type == "dislike") {
            if (comment.isdisLike == 1) {
              comment.totalDisLike =
                comment?.totalDisLike == 0 ? 0 : comment?.totalDisLike - 1;
            } else {
              comment.totalDisLike = comment?.totalDisLike + 1;
              if (comment.isLike) {
                comment.totalLikes =
                  comment?.totalLikes == 0 ? 0 : comment?.totalLikes - 1;
                comment.isLike = !comment.isLike;
              }
            }
            comment.isdisLike = !comment.isdisLike;
          } else if (type == "like") {
            if (comment.isLike == 1) {
              comment.totalLikes =
                comment?.totalLikes == 0 ? 0 : comment?.totalLikes - 1;
            } else {
              comment.totalLikes = comment?.totalLikes + 1;
              if (comment.isdisLike) {
                comment.totalDisLike =
                  comment?.totalDisLike == 0 ? 0 : comment?.totalDisLike - 1;
                comment.isdisLike = !comment.isdisLike;
              }
            }
            comment.isLike = !comment.isLike;
          }
          setLoad(!load);
          // fetchNewsComments(null, commentPage);
        } else {
        }
      } catch (err) {}
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem("prev_authroute", window.location.pathname);
    }
  };

  const toggleReplies = (commentId) => {
    setExpandedReplies((prev) => ({
      ...prev,
      [commentId]: !prev[commentId],
    }));
  };
  return (
    <>
      {isLoading ? (
        <div className="app-loader">
          <Loader />
        </div>
      ) : (
        <>
          {/* <NewsMeta singleNewsData={singleNewsData} /> */}
          <Box className="news-full-details">
            <Typography
              variant="h4"
              dangerouslySetInnerHTML={{
                __html: singleNewsData?.title,
              }}
            ></Typography>
            <Box className="news-date-time">
              <Typography className="date">
                {" "}
                {moment
                  .utc(singleNewsData?.rapidCreatedAt)
                  .local()
                  .format("DD/MM/YYYY")}
              </Typography>
              <span>|</span>
              <Typography className="time">
                {moment
                  .utc(singleNewsData?.rapidCreatedAt)
                  .local()
                  .format("hh:mm A")}
              </Typography>
            </Box>
            <Box className="news-sub-details">
              <Box className="news-sub-left">
                <Box className="news-logo">
                  {singleNewsData?.publishedBy?.logo ? (
                    singleNewsData?.NewsProviderId === 2 ||
                    singleNewsData?.NewsProviderId === 3 ? (
                      <img
                        src={singleNewsData?.publishedBy?.logo}
                        alt="logo"
                        width="100%"
                      />
                    ) : (
                      <img
                        src={
                          singleNewsData?.publishedBy?.logo?.includes("uploads")
                            ? singleNewsData?.publishedBy?.logo
                            : `https://www.livescore.com${singleNewsData?.publishedBy?.logo}`
                        }
                        alt="logo"
                        width="100%"
                      />
                    )
                  ) : (
                    <img src={Author_Placeholder} alt="logo" width="100%" />
                  )}
                </Box>
                <Box>
                  <Typography className="name">
                    {singleNewsData?.authors?.[0]?.name}
                  </Typography>
                  <Typography variant=" " className="live-score">
                    {singleNewsData?.publishedBy?.name}
                  </Typography>
                </Box>
              </Box>
              <Box className="news-sub-right">
                <Tooltip
                  placement="bottom-end"
                  interactive
                  arrow
                  title={
                    <Box className="share-option">
                      <Box className="social-option-box">
                        <Box className="option">
                          <CopyToClipboard text={link} onCopy={handleCopy}>
                            <Box className="cursor-pointer">
                              <img src={Copy} alt="Copy" />
                              <Typography className="option-name">
                                Copy Link
                              </Typography>
                            </Box>
                          </CopyToClipboard>
                        </Box>
                        <Box className="option">
                          <WhatsappShareButton url={link}>
                            <img src={Wp} alt="Whatsapp" />
                          </WhatsappShareButton>
                          <Typography className="option-name">
                            Whatsapp
                          </Typography>
                        </Box>
                        <Box className="option">
                          <FacebookShareButton url={link}>
                            <img src={Facebook} alt="Facebook" />
                          </FacebookShareButton>
                          <Typography className="option-name">
                            Facebook
                          </Typography>
                        </Box>
                        <Box className="option cursor-pointer">
                          <img
                            src={Messenger}
                            alt="Messenger"
                            className="social-icon"
                            onClick={() => shareOnMessenger(link)}
                          />
                          <Typography className="option-name">
                            Messenger
                          </Typography>
                        </Box>
                        <Box className="option">
                          <TwitterShareButton url={link}>
                            <img src={Twitter_X} alt="Twitter_X" />
                          </TwitterShareButton>
                          <Typography className="option-name">
                            Twitter
                          </Typography>
                        </Box>
                        <Box className="option">
                          <LinkedinShareButton url={link}>
                            <img src={Linkedin} alt="Linkedin" />
                          </LinkedinShareButton>
                          <Typography className="option-name">
                            Linkedin
                          </Typography>
                        </Box>
                        {/* <Box className="option">
                    <img src={Snapchat} alt="Snapchat" />
                    <Typography className="option-name">Snapchat</Typography>
                  </Box>
                  <Box className="option">
                    {" "}
                    <img src={Message} alt="Message" />
                    <Typography className="option-name">Message</Typography>
                  </Box> */}
                      </Box>
                    </Box>
                  }
                  // open={sub?.secondSubMenu?.length === 9 ? true : false}
                  classes={{
                    tooltip: "news-share-tooltip",
                  }}
                  PopperProps={{
                    style: {
                      zIndex: 1200,
                    },
                  }}
                >
                  <Box className="cursor-pointer">
                    <ShareNews />
                  </Box>
                </Tooltip>

                <Box
                  className="cursor-pointer"
                  onClick={(e) => handleNewsSave(e, singleNewsData)}
                >
                  <Tooltip
                    placement="bottom"
                    arrow
                    title='Save to "Saved articles"'
                    classes={{
                      tooltip: "news-tooltip",
                    }}
                  >
                    {singleNewsData?.isSaved === 0 ? (
                      <SaveNews />
                    ) : (
                      <SavedNews />
                    )}
                  </Tooltip>
                </Box>

                <Tooltip
                  placement="bottom"
                  arrow
                  title="Comments"
                  classes={{
                    tooltip: "news-tooltip",
                  }}
                >
                  <Box className="comments-section cursor-pointer">
                    <CommentNews />
                    <span className="comments-count">
                      {singleNewsData?.totalComments}
                    </span>
                  </Box>
                </Tooltip>
              </Box>
            </Box>
            <Box className="news-img">
              {singleNewsData?.mainMedia?.gallery ||
              singleNewsData?.mainMedia?.[0]?.gallery ? (
                <img
                  src={
                    singleNewsData?.mainMedia?.gallery
                      ? singleNewsData?.mainMedia?.gallery?.url &&
                        singleNewsData?.mainMedia?.gallery?.url !== ""
                        ? singleNewsData?.mainMedia?.gallery?.url
                        : DefaultImg
                      : singleNewsData?.mainMedia?.[0]?.gallery
                        ? singleNewsData?.mainMedia?.[0]?.gallery?.url &&
                          singleNewsData?.mainMedia?.[0]?.gallery?.url !== ""
                          ? singleNewsData?.mainMedia?.[0]?.gallery?.url
                          : DefaultImg
                        : DefaultImg
                  }
                  alt={
                    singleNewsData?.mainMedia?.gallery
                      ? singleNewsData?.mainMedia?.gallery?.alt
                      : singleNewsData?.mainMedia?.[0]?.gallery
                        ? singleNewsData?.mainMedia?.[0]?.gallery?.alt
                        : ""
                  }
                  width="100%"
                />
              ) : (
                <></>
              )}
            </Box>
            {singleNewsData?.mainMedia?.gallery ||
            singleNewsData?.mainMedia?.[0]?.gallery ? (
              <Typography
                className="img-alt img-alt-bottom"
                dangerouslySetInnerHTML={{
                  __html: singleNewsData?.mainMedia?.gallery
                    ? singleNewsData?.mainMedia?.gallery?.alt
                    : singleNewsData?.mainMedia?.[0]?.gallery
                      ? singleNewsData?.mainMedia?.[0]?.gallery?.alt
                      : "",
                }}
              >
                {/* {singleNewsData?.mainMedia?.gallery
                    ? singleNewsData?.mainMedia?.gallery?.alt
                    : singleNewsData?.mainMedia?.[0]?.gallery
                    ? singleNewsData?.mainMedia?.[0]?.gallery?.alt
                    : ""}
                    : ""} */}
              </Typography>
            ) : (
              <></>
            )}

            {/* News provider wise content set beacuse of body data to much so page unresponsive error show this code comment */}

            {/* singleNewsData?.NewsProviderId === 2 ||
            singleNewsData?.NewsProviderId === 3 ? (
              <Box className="custom-news">
                <DynamicNewsScript newsContent={singleNewsData?.body} />
                {/* <Typography
                  className="para-1"
                  dangerouslySetInnerHTML={{
                    __html: singleNewsData?.body,
                  }}
                  ref={advRef}
                ></Typography> /}
              </Box>
            )  */}
            {singleNewsData?.NewsProviderId === 2 ? (
              <Box className="custom-news">
                <DynamicNewsScript newsContent={singleNewsData?.body} />
              </Box>
            ) : singleNewsData?.NewsProviderId === 3 ? (
              <Box className="custom-news">
                <Typography
                  className="para-1"
                  dangerouslySetInnerHTML={{
                    __html: singleNewsData?.body,
                  }}
                  ref={advRef}
                ></Typography>
              </Box>
            ) : (
              singleNewsData?.body?.map((item, index) => {
                return (
                  <>
                    {item?.type === "editor_block" ? (
                      <Typography
                        className="para-1"
                        dangerouslySetInnerHTML={{
                          __html: item?.data?.content,
                        }}
                      ></Typography>
                    ) : item?.type === "image" ? (
                      <Box className="news-img-2">
                        <img
                          src={item?.image?.gallery?.url}
                          alt={item?.image?.gallery?.alt}
                        />
                        <Typography className="img-alt">
                          {item?.image?.gallery?.alt}
                        </Typography>
                      </Box>
                    ) : item?.type === "link" ? (
                      <a href={item?.data?.link} target="_blank">
                        {item?.data?.text}
                      </a>
                    ) : item?.type === "embed" ? (
                      item?.data?.embed_type === "social" ? (
                        <Tweet
                          options={{ align: "center" }}
                          tweetId={item?.data?.content?.slice(8)}
                        />
                      ) : /* <Box className="ifeame-section"> 
                        <iframe
                          scrolling="no"
                          frameborder="0"
                          allowtransparency="true"
                          allowfullscreen="true"
                          src={`https://platform.twitter.com/embed/Tweet.html?dnt=false&embedId=twitter-widget-1&frame=false&hideCard=false&hideThread=false&id=${item?.data?.content?.slice(
                            8
                          )}`}
                          width="550px"
                          // height="576px"
                          className="twitter-tag"
                        ></iframe> 
                        
                      </Box>*/

                      item?.data?.embed_type === "other" ? (
                        <Box
                          className="instagram-post"
                          dangerouslySetInnerHTML={{
                            __html: item?.data?.content,
                          }}
                        ></Box>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {index == 5 ? (
                      fetchAds(
                        1,
                        "100%",
                        Config?.release == "IN" ? BannerIndia : BetweenContent,
                      )
                    ) : (
                      <></>
                    )}
                  </>
                );
              })
            )}
            <Box className="tages-details">
              {singleNewsData?.NewsTagRelations?.length > 0 ? (
                <Typography className="tages">Tags:</Typography>
              ) : (
                <></>
              )}
              <Box className="tage-box">
                {singleNewsData?.NewsTagRelations?.map((obj) => (
                  <span className="news-tages">{obj?.NewsTag?.title}</span>
                ))}
              </Box>
            </Box>
          </Box>

          {/* {screenWidth > 1090 ? (
            " "
          ) : (
            <Box className="related-articles-layout">
              <Box className="related-articles-header">
                <Typography variant="h4">Related Articles</Typography>
              </Box>
              {singleNewsData?.NewsRelatedArticles?.length > 0 ? (
                <Slider {...settings1}>
                  {singleNewsData?.NewsRelatedArticles?.map((item, index) => (
                    <Grid item lg={6} key={index}>
                      <Box
                        className="single-top-stories"
                        onClick={() =>
                          navigate(
                            `/news/${singleNewsData?.NewsCategoryId}/${item?.relatedArticleId}`
                          )
                        }
                      >
                        <Box className="top-stories-img">
                          <img
                            src={
                              item?.NewsArticle?.mainMedia?.gallery
                                ? item?.NewsArticle?.mainMedia?.gallery?.url !==
                                  ""
                                  ? item?.NewsArticle?.mainMedia?.gallery?.url
                                  : DefaultImg
                                : item?.NewsArticle?.mainMedia?.[0]?.gallery
                                ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                    ?.url !== ""
                                  ? item?.NewsArticle?.mainMedia?.[0]?.gallery
                                      ?.url
                                  : DefaultImg
                                : DefaultImg
                            }
                            alt={
                              item?.NewsArticle?.mainMedia?.gallery
                                ? item?.NewsArticle?.mainMedia?.gallery?.alt
                                : item?.NewsArticle?.mainMedia?.[0]?.gallery
                                ? item?.NewsArticle?.mainMedia?.[0]?.gallery.alt
                                : ""
                            }
                          />
                        </Box>
                        <Box className="stories-details">
                          <Typography
                            className="details"
                            dangerouslySetInnerHTML={{
                              __html: item?.NewsArticle?.title
                            }}
                          ></Typography>
                          <Box className="text-details">
                            <span className="stories-tag">
                              {fetchCategoryName(singleNewsData.NewsCategoryId)}
                            </span>
                            <Typography className="stories-time">
                              <span className="watch">
                                <Watch />
                              </span>
                              {fetchNewsTime(item?.NewsArticle?.rapidCreatedAt)}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Slider>
              ) : (
                <Box className="no-data">
                  <Typography>No Data Available</Typography>
                </Box>
              )}
            </Box>
          )} */}
        </>
      )}
      {/* {handleRestrictedUser() && ( */}
      <Box className="news-comments-section">
        <Box className="news-comments-wrap">
          {isCommentLoading ? (
            <div className="app-loader">
              <Loader />
            </div>
          ) : (
            <>
              <Box className="leave-comments-section">
                <Typography className="total-comments">
                  {singleNewsData?.totalComments} Comments
                </Typography>
                <Typography className="leave-a-comment">
                  Leave a comment
                </Typography>
                <Box className="field-container mb-12">
                  <TextareaAutosize
                    id="outlined-basic"
                    variant="outlined"
                    className="comment-textarea-field"
                    placeholder="Join the discussion..."
                    name="LeaveAComment"
                    maxRows={3}
                    minRows={1}
                    onChange={(e) => setLeaveComment(e?.target?.value)}
                    value={leaveComment}
                  />
                  <FormControlLabel
                    className="comment-email-check"
                    onChange={(e) =>
                      handleLeaveCommentChange(e?.target?.checked)
                    }
                    control={
                      <Checkbox
                        size="small"
                        icon={<CheckboxUnChecked className="radio-icon" />}
                        checkedIcon={<CheckBoxChecked className="radio-icon" />}
                        disableRipple
                        className="comment-email-checkbox"
                      />
                    }
                    label={"Email me if someone responds to my comment."}
                    name="fruits"
                    checked={emailLeaveChecked}
                  />
                </Box>
                <Button
                  className="comments-button"
                  onClick={() => handlePostLeaveComment()}
                >
                  Post
                </Button>
              </Box>
              {commentNewsData?.length > 0 ? (
                <Box className="reply-comments-section">
                  <Box className="parent-comment-section">
                    {/* parent comment section  */}
                    {commentNewsData?.map((item, index) => {
                      const isExpanded = expandedReplies[item?.id];
                      const repliesToShow = isExpanded
                        ? item?.replyPost
                        : item?.replyPost.slice(0, 2);
                      return (
                        <>
                          <Box className="parent-comment comment-wrap">
                            <Box className="comments-author">
                              <img
                                src={
                                  item?.fromUser?.Media?.filePath
                                    ? item?.fromUser?.Media?.filePath?.includes(
                                        "uploads",
                                      )
                                      ? Config.mediaURL +
                                        item?.fromUser?.Media?.filePath
                                      : item?.fromUser?.Media?.filePath
                                    : CommentsAuthor
                                }
                                alt=""
                              />
                            </Box>
                            <Box className="comments-details-wrap">
                              <Box className="comments-details-author">
                                <Typography className="author">
                                  <span className="author-name">
                                    {item?.fromUser?.firstName}{" "}
                                    {item?.fromUser?.lastName}
                                  </span>
                                  <span className="comment-time">
                                    {" "}
                                    {fetchNewsTime(item?.createdAt)}
                                  </span>
                                </Typography>
                                {screenWidth >= 799 && (
                                  <Typography className="action-comments">
                                    <Box className="action">
                                      <span
                                        className={
                                          item?.status === "deleted"
                                            ? "light like"
                                            : "like cursor-pointer"
                                        }
                                        onClick={() =>
                                          item?.status === "deleted"
                                            ? ""
                                            : handleLikeDisLike(
                                                item,
                                                item,
                                                null,
                                                "like",
                                              )
                                        }
                                      >
                                        <Like
                                          className={
                                            item?.isLike ? "active" : ""
                                          }
                                        />
                                      </span>
                                      <span>{item?.totalLikes}</span>
                                    </Box>
                                    <Box className="action">
                                      <span
                                        className={
                                          item?.status === "deleted"
                                            ? "light dislike"
                                            : "dislike cursor-pointer"
                                        }
                                        onClick={() =>
                                          item?.status === "deleted"
                                            ? ""
                                            : handleLikeDisLike(
                                                item,
                                                item,
                                                null,
                                                "dislike",
                                              )
                                        }
                                      >
                                        <DisLike
                                          className={
                                            item?.isdisLike ? "active" : ""
                                          }
                                        />
                                      </span>
                                      <span>{item?.totalDisLike}</span>
                                    </Box>
                                  </Typography>
                                )}
                              </Box>
                              <Typography className="comments">
                                {item?.comment}
                              </Typography>
                              <Box className="mobile-reply-btn">
                                <Box className="reply-button">
                                  {item?.status !== "deleted" && (
                                    <span
                                      className="reply cursor-pointer"
                                      onClick={() =>
                                        handleReply(item, index, null, null)
                                      }
                                    >
                                      Reply
                                    </span>
                                  )}
                                  {item?.fromUser?.id === userData?.id && (
                                    <span
                                      className={
                                        item?.status === "deleted"
                                          ? "light delete"
                                          : "delete cursor-pointer"
                                      }
                                      onClick={() =>
                                        item?.status === "deleted"
                                          ? ""
                                          : handleDeleteModal(item)
                                      }
                                    >
                                      {item?.status === "deleted"
                                        ? "Deleted"
                                        : "Delete"}
                                    </span>
                                  )}
                                </Box>
                                {screenWidth < 799 && (
                                  <Typography className="action-comments">
                                    <Box className="action">
                                      <span
                                        className={
                                          item?.status === "deleted"
                                            ? "light like"
                                            : "like cursor-pointer"
                                        }
                                        onClick={() =>
                                          item?.status === "deleted"
                                            ? ""
                                            : handleLikeDisLike(
                                                item,
                                                item,
                                                null,
                                                "like",
                                              )
                                        }
                                      >
                                        <Like
                                          className={
                                            item?.isLike ? "active" : ""
                                          }
                                        />
                                      </span>
                                      <span>{item?.totalLikes}</span>
                                    </Box>
                                    <Box className="action">
                                      <span
                                        className={
                                          item?.status === "deleted"
                                            ? "light dislike"
                                            : "dislike cursor-pointer"
                                        }
                                        onClick={() =>
                                          item?.status === "deleted"
                                            ? ""
                                            : handleLikeDisLike(
                                                item,
                                                item,
                                                null,
                                                "dislike",
                                              )
                                        }
                                      >
                                        <DisLike
                                          className={
                                            item?.isdisLike ? "active" : ""
                                          }
                                        />
                                      </span>
                                      <span>{item?.totalDisLike}</span>
                                    </Box>
                                  </Typography>
                                )}
                              </Box>
                              {replyOption &&
                                item?.id === replyCommentId?.parentCommentId &&
                                replyCommentId?.childCommentId === null && (
                                  <Box className="reply-box">
                                    <Typography className="replying-to">
                                      Replying to{" "}
                                      <span>
                                        {item?.fromUser?.firstName}{" "}
                                        {item?.fromUser?.lastName}
                                      </span>
                                    </Typography>
                                    <Box className="field-container mb-12">
                                      <TextareaAutosize
                                        id="outlined-basic"
                                        variant="outlined"
                                        className="comment-textarea-field"
                                        placeholder="Join the discussion..."
                                        name="replyComment"
                                        maxRows={3}
                                        minRows={1}
                                        onChange={(e) =>
                                          setReplyComment(e?.target?.value)
                                        }
                                        value={replyComment}
                                      />

                                      <FormControlLabel
                                        className="comment-email-check"
                                        onChange={(e) =>
                                          handleChange(e?.target?.checked)
                                        }
                                        control={
                                          <Checkbox
                                            size="small"
                                            icon={
                                              <CheckboxUnChecked className="radio-icon" />
                                            }
                                            checkedIcon={
                                              <CheckBoxChecked className="radio-icon" />
                                            }
                                            disableRipple
                                            className="comment-email-checkbox"
                                          />
                                        }
                                        label={
                                          "Email me if someone responds to my comment."
                                        }
                                        name="fruits"
                                        checked={emailChecked}
                                      />
                                    </Box>
                                    <Box className="reply-box-button">
                                      <Button
                                        className="comments-button"
                                        disabled={postCommentLoading}
                                        onClick={() =>
                                          handleReplyPostComment(item, item)
                                        }
                                      >
                                        Post
                                      </Button>
                                      <Button
                                        variant="outlined"
                                        className="comments-button Cancel"
                                        onClick={() => {
                                          setReplyOption(false);
                                          setReplyComment("");
                                          setEmailChecked(false);
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    </Box>
                                  </Box>
                                )}

                              {/* reply comments section  */}
                              {item?.replyPost?.length > 0 ? (
                                repliesToShow?.map((obj, objIndex) => {
                                  return (
                                    <>
                                      <Box className="comment-wrap comment-border-top">
                                        <Box className="comments-author">
                                          <img
                                            src={
                                              obj?.fromUser?.Media?.filePath
                                                ? obj?.fromUser?.Media?.filePath?.includes(
                                                    "uploads",
                                                  )
                                                  ? Config.mediaURL +
                                                    obj?.fromUser?.Media
                                                      ?.filePath
                                                  : obj?.fromUser?.Media
                                                      ?.filePath
                                                : CommentsAuthor
                                            }
                                            alt=""
                                          />
                                        </Box>
                                        <Box className="comments-details-wrap">
                                          <Box className="comments-details-author">
                                            <Typography className="author">
                                              <span className="author-name">
                                                {obj?.fromUser?.firstName}{" "}
                                                {obj?.fromUser?.lastName}
                                              </span>
                                              <span className="comment-time">
                                                {fetchNewsTime(obj?.createdAt)}
                                              </span>
                                            </Typography>
                                            {screenWidth >= 799 && (
                                              <Typography className="action-comments">
                                                <Box className="action">
                                                  <span
                                                    className={
                                                      obj?.status === "deleted"
                                                        ? "light like "
                                                        : "like cursor-pointer"
                                                    }
                                                    onClick={() =>
                                                      obj?.status === "deleted"
                                                        ? ""
                                                        : handleLikeDisLike(
                                                            obj,
                                                            item,
                                                            obj,
                                                            "like",
                                                          )
                                                    }
                                                  >
                                                    <Like
                                                      className={
                                                        obj?.isLike
                                                          ? "active"
                                                          : ""
                                                      }
                                                    />
                                                  </span>
                                                  <span>{obj?.totalLikes}</span>
                                                </Box>
                                                <Box className="action">
                                                  <span
                                                    className={
                                                      obj?.status === "deleted"
                                                        ? "light dislike"
                                                        : "dislike cursor-pointer"
                                                    }
                                                    onClick={() =>
                                                      obj?.status === "deleted"
                                                        ? ""
                                                        : handleLikeDisLike(
                                                            obj,
                                                            item,
                                                            obj,
                                                            "dislike",
                                                          )
                                                    }
                                                  >
                                                    <DisLike
                                                      className={
                                                        obj?.isdisLike
                                                          ? "active"
                                                          : ""
                                                      }
                                                    />
                                                  </span>
                                                  <span>
                                                    {obj?.totalDisLike}
                                                  </span>
                                                </Box>
                                              </Typography>
                                            )}
                                          </Box>
                                          <Typography className="comments">
                                            {obj?.comment}
                                          </Typography>
                                          <Box className="mobile-reply-btn">
                                            <Box className="reply-button">
                                              {obj?.status !== "deleted" && (
                                                <span
                                                  className="reply cursor-pointer"
                                                  onClick={() =>
                                                    handleReply(
                                                      item,
                                                      index,
                                                      obj,
                                                      objIndex,
                                                    )
                                                  }
                                                >
                                                  Reply
                                                </span>
                                              )}
                                              {obj?.fromUser?.id ===
                                                userData?.id && (
                                                <span
                                                  className={
                                                    obj?.status === "deleted"
                                                      ? "light delete"
                                                      : "delete cursor-pointer"
                                                  }
                                                  onClick={() =>
                                                    obj?.status === "deleted"
                                                      ? ""
                                                      : handleDeleteModal(obj)
                                                  }
                                                >
                                                  {obj?.status === "deleted"
                                                    ? "Deleted"
                                                    : "Delete"}
                                                </span>
                                              )}
                                            </Box>
                                            {screenWidth < 799 && (
                                              <Typography className="action-comments">
                                                <Box className="action">
                                                  <span
                                                    className={
                                                      obj?.status === "deleted"
                                                        ? "light like "
                                                        : "like cursor-pointer"
                                                    }
                                                    onClick={() =>
                                                      obj?.status === "deleted"
                                                        ? ""
                                                        : handleLikeDisLike(
                                                            obj,
                                                            item,
                                                            obj,
                                                            "like",
                                                          )
                                                    }
                                                  >
                                                    <Like
                                                      className={
                                                        obj?.isLike
                                                          ? "active"
                                                          : ""
                                                      }
                                                    />
                                                  </span>
                                                  <span>{obj?.totalLikes}</span>
                                                </Box>
                                                <Box className="action">
                                                  <span
                                                    className={
                                                      obj?.status === "deleted"
                                                        ? "light dislike"
                                                        : "dislike cursor-pointer"
                                                    }
                                                    onClick={() =>
                                                      obj?.status === "deleted"
                                                        ? ""
                                                        : handleLikeDisLike(
                                                            obj,
                                                            item,
                                                            obj,
                                                            "dislike",
                                                          )
                                                    }
                                                  >
                                                    <DisLike
                                                      className={
                                                        obj?.isdisLike
                                                          ? "active"
                                                          : ""
                                                      }
                                                    />
                                                  </span>
                                                  <span>
                                                    {obj?.totalDisLike}
                                                  </span>
                                                </Box>
                                              </Typography>
                                            )}
                                          </Box>
                                          {replyOption &&
                                            item?.id ===
                                              replyCommentId?.parentCommentId &&
                                            obj?.id ===
                                              replyCommentId?.childCommentId && (
                                              <Box className="reply-box">
                                                <Typography className="replying-to">
                                                  Replying to{" "}
                                                  <span>
                                                    {" "}
                                                    {
                                                      obj?.fromUser?.firstName
                                                    }{" "}
                                                    {obj?.fromUser?.lastName}
                                                  </span>
                                                </Typography>
                                                <Box className="field-container mb-12">
                                                  <TextareaAutosize
                                                    id="outlined-basic"
                                                    variant="outlined"
                                                    className="comment-textarea-field"
                                                    placeholder="Join the discussion..."
                                                    name="replyComment"
                                                    maxRows={3}
                                                    minRows={1}
                                                    onChange={(e) =>
                                                      setReplyComment(
                                                        e?.target?.value,
                                                      )
                                                    }
                                                    value={replyComment}
                                                  />
                                                  <FormControlLabel
                                                    className="comment-email-check"
                                                    onChange={(e) =>
                                                      handleChange(
                                                        e?.target?.checked,
                                                      )
                                                    }
                                                    control={
                                                      <Checkbox
                                                        size="small"
                                                        icon={
                                                          <CheckboxUnChecked className="radio-icon" />
                                                        }
                                                        checkedIcon={
                                                          <CheckBoxChecked className="radio-icon" />
                                                        }
                                                        disableRipple
                                                        className="comment-email-checkbox"
                                                      />
                                                    }
                                                    label={
                                                      "Email me if someone responds to my comment."
                                                    }
                                                    name="fruits"
                                                    checked={emailChecked}
                                                  />
                                                </Box>
                                                <Box className="reply-box-button">
                                                  <Button
                                                    className="comments-button"
                                                    disabled={
                                                      postCommentLoading
                                                    }
                                                    onClick={() =>
                                                      handleReplyPostComment(
                                                        obj,
                                                        item,
                                                        obj,
                                                      )
                                                    }
                                                  >
                                                    Post
                                                  </Button>
                                                  <Button
                                                    variant="outlined"
                                                    className="comments-button Cancel"
                                                    onClick={() => {
                                                      setReplyOption(false);
                                                      setReplyComment("");
                                                      setEmailChecked(false);
                                                    }}
                                                  >
                                                    Cancel
                                                  </Button>
                                                </Box>
                                              </Box>
                                            )}
                                        </Box>
                                      </Box>

                                      {obj?.replyPost?.length > 0 ? (
                                        obj?.replyPost?.map((reply, rIndex) => {
                                          return (
                                            <>
                                              <Box
                                                className="comment-wrap comment-border-top reply-post-comment"
                                                key={rIndex}
                                              >
                                                <Box className="comments-author">
                                                  <img
                                                    src={
                                                      reply?.fromUser?.Media
                                                        ?.filePath
                                                        ? reply?.fromUser?.Media?.filePath?.includes(
                                                            "uploads",
                                                          )
                                                          ? Config.mediaURL +
                                                            reply?.fromUser
                                                              ?.Media?.filePath
                                                          : reply?.fromUser
                                                              ?.Media?.filePath
                                                        : CommentsAuthor
                                                    }
                                                    alt=""
                                                  />
                                                </Box>
                                                <Box className="comments-details-wrap">
                                                  <Box className="comments-details-author">
                                                    <Typography className="author">
                                                      <span className="author-name">
                                                        {
                                                          reply?.fromUser
                                                            ?.firstName
                                                        }{" "}
                                                        {
                                                          reply?.fromUser
                                                            ?.lastName
                                                        }
                                                      </span>
                                                      <span className="comment-time">
                                                        {fetchNewsTime(
                                                          reply?.createdAt,
                                                        )}
                                                      </span>
                                                    </Typography>
                                                    {screenWidth >= 799 && (
                                                      <Typography className="action-comments">
                                                        <Box className="action">
                                                          <span
                                                            className={
                                                              reply?.status ===
                                                              "deleted"
                                                                ? "light like"
                                                                : "like cursor-pointer"
                                                            }
                                                            onClick={() =>
                                                              reply?.status ===
                                                              "deleted"
                                                                ? ""
                                                                : handleLikeDisLike(
                                                                    reply,
                                                                    item,
                                                                    obj,
                                                                    "like",
                                                                  )
                                                            }
                                                          >
                                                            <Like
                                                              className={
                                                                reply?.isLike
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                            />
                                                          </span>
                                                          <span>
                                                            {reply?.totalLikes}
                                                          </span>
                                                        </Box>
                                                        <Box className="action">
                                                          <span
                                                            className={
                                                              reply?.status ===
                                                              "deleted"
                                                                ? "light dislike"
                                                                : "dislike cursor-pointer"
                                                            }
                                                            onClick={() =>
                                                              reply?.status ===
                                                              "deleted"
                                                                ? ""
                                                                : handleLikeDisLike(
                                                                    reply,
                                                                    item,
                                                                    obj,
                                                                    "dislike",
                                                                  )
                                                            }
                                                          >
                                                            <DisLike
                                                              className={
                                                                reply?.isdisLike
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                            />
                                                          </span>
                                                          <span>
                                                            {
                                                              reply?.totalDisLike
                                                            }
                                                          </span>
                                                        </Box>
                                                      </Typography>
                                                    )}
                                                  </Box>
                                                  <Typography className="comments">
                                                    {reply?.comment}
                                                  </Typography>
                                                  <Box className="mobile-reply-btn">
                                                    <Box className="reply-button">
                                                      {reply?.fromUser?.id ===
                                                        userData?.id && (
                                                        <span
                                                          className={
                                                            reply?.status ===
                                                            "deleted"
                                                              ? "light delete"
                                                              : "delete cursor-pointer"
                                                          }
                                                          onClick={() =>
                                                            reply?.status ===
                                                            "deleted"
                                                              ? ""
                                                              : handleDeleteModal(
                                                                  reply,
                                                                )
                                                          }
                                                        >
                                                          {reply?.status ===
                                                          "deleted"
                                                            ? "Deleted"
                                                            : "Delete"}
                                                        </span>
                                                      )}
                                                    </Box>
                                                    {screenWidth < 799 && (
                                                      <Typography className="action-comments">
                                                        <Box className="action">
                                                          <span
                                                            className={
                                                              reply?.status ===
                                                              "deleted"
                                                                ? "light like"
                                                                : "like cursor-pointer"
                                                            }
                                                            onClick={() =>
                                                              reply?.status ===
                                                              "deleted"
                                                                ? ""
                                                                : handleLikeDisLike(
                                                                    reply,
                                                                    item,
                                                                    obj,
                                                                    "like",
                                                                  )
                                                            }
                                                          >
                                                            <Like
                                                              className={
                                                                reply?.isLike
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                            />
                                                          </span>
                                                          <span>
                                                            {reply?.totalLikes}
                                                          </span>
                                                        </Box>
                                                        <Box className="action">
                                                          <span
                                                            className={
                                                              reply?.status ===
                                                              "deleted"
                                                                ? "light dislike"
                                                                : "dislike cursor-pointer"
                                                            }
                                                            onClick={() =>
                                                              reply?.status ===
                                                              "deleted"
                                                                ? ""
                                                                : handleLikeDisLike(
                                                                    reply,
                                                                    item,
                                                                    obj,
                                                                    "dislike",
                                                                  )
                                                            }
                                                          >
                                                            <DisLike
                                                              className={
                                                                reply?.isdisLike
                                                                  ? "active"
                                                                  : ""
                                                              }
                                                            />
                                                          </span>
                                                          <span>
                                                            {
                                                              reply?.totalDisLike
                                                            }
                                                          </span>
                                                        </Box>
                                                      </Typography>
                                                    )}
                                                  </Box>
                                                </Box>
                                              </Box>
                                            </>
                                          );
                                        })
                                      ) : (
                                        <></>
                                      )}
                                    </>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                              {item.replyPost.length > 2 && (
                                <Box className="show-all-replies">
                                  <Typography
                                    className="show-all-text"
                                    onClick={() => toggleReplies(item.id)}
                                  >
                                    <span>
                                      {isExpanded ? <Minus /> : <Plus />}
                                    </span>
                                    {isExpanded
                                      ? "Hide replies"
                                      : "Show all replies"}
                                  </Typography>
                                </Box>
                              )}
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                </Box>
              ) : (
                <></>
              )}
              {/* <Box className="no-comments-Available">
                    No Comments Available
                  </Box> */}
              {!isCommentLoading &&
              commentNewsData &&
              commentNewsData?.length > 0 ? (
                <Box className="pagination-section">
                  <Pagination
                    disabled={commentCount / 3 > 1 ? false : true}
                    page={commentPage}
                    onChange={(e, value) => handlePaginationClick(e, value)}
                    count={pageNumbers[pageNumbers?.length - 1]}
                    siblingCount={2}
                    boundaryCount={1}
                    size="small"
                  />
                </Box>
              ) : (
                <></>
              )}
            </>
          )}
        </Box>
      </Box>
      {/* )} */}

      {fetchAds(
        2,
        "100%",
        Config?.release == "IN" ? BannerIndia : AboveOurPartner,
      )}
      <OurPartner />
      {Config?.release == "AU" && fetchAds(3, "102px", BelowOurPartner)}

      <DialogBox
        open={commentDeleteModal}
        onClose={handleDeleteModalClose}
        handleClose={handleDeleteModalClose}
        title={false}
        content={
          <>
            <Box className="delete-dialogbox comment-delete-dialogbox">
              <Typography className="delete-title">Delete comment?</Typography>
              <Typography className="sub-title">
                Are you sure you wish to proceed with deleting your comment?
                This cannot be undone.
              </Typography>
              <Box className="delete-btn">
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-cancel"
                  onClick={() => handleDeleteModalClose()}
                >
                  Cancel
                </Button>
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="btn-save"
                  onClick={() => {
                    handleCommentDelete(commentDeleteId);
                  }}
                >
                  Delete
                </Button>
              </Box>
            </Box>
          </>
        }
        closeIcon={false}
      />
    </>
  );
};

export default IndividualNews;

import React, { useCallback, useContext, useEffect, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Button,
  SwipeableDrawer,
  Typography,
  Tab,
  Tabs,
} from "@mui/material";
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import banner from "../../../../assets/images/banner/smart-b-banner-secodary.webp";
import pageHeaderIndia from "../../../../assets/images/ad-placeholder/IPL_Page_Banner_1122x218_3.jpg";
import { ReactComponent as SelectIndicator } from "src/assets/images/selectdropdownindicator.svg";
import WhiteBulb from "../../../../assets/images/icons/whiteBulb.png";
import BottomSheet from "../../../../assets/images/filterBottomsheet.png";
import BottomArrowSheet from "../../../../assets/images/bottomSheetDownArrow.png";
import AdBannner from "../../AdBanner/AdBanner";
import { IntlContext } from "src/App";
import { useSelector } from "react-redux";
import { Config, fetchFromStorage } from "src/helpers/context";
import Select, { components } from "react-select";
import "./homeLanding.scss";
import HomeLandingRight from "./homeLandingRight";
import HomeLandingLeft from "./homeLandingLeft";
import OurPartner from "../../ourPartners";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import AsyncSelect from "react-select/async";

import _ from "lodash";
import LandingICCRankingDetails from "./homeLandingRight/landingICCRankingDetails";
import LandingTableDetails from "./homeLandingRight/landingTableDetails";
import LandingFixturesResultDetails from "./homeLandingRight/landingFixturesResultsDetails";
import { fetchRestrictedRoute } from "src/helpers/store/Actions/RestrictedRoute";
import { useDispatch } from "react-redux";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <SelectIndicator />
    </components.DropdownIndicator>
  );
};

const TeamOptions = [
  { value: "Team1", label: "Team1" },
  { value: "Team2", label: "Team2" },
  { value: "Team3", label: "Team3" },
  { value: "Team4", label: "Team4" },
  { value: "Team5", label: "Team5" },
  { value: "Team6", label: "Team6" },
];

const HomeLanding = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const searchParamsMenu = new URLSearchParams(location.search);
  const touranamentId = searchParamsMenu.get("touranamentId");
  const teamId = searchParamsMenu.get("teamsId");
  const teamName = searchParamsMenu.get("team_name");
  const ReduxAdsData = useSelector((state) => state?.reduxData?.AdsData);
  const intlContext = useContext(IntlContext);
  const localesData = intlContext?.messages;
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [PageHeaderData, setPageHeaderData] = useState([]);
  const [visibleAds, setVisibleAds] = useState([]);
  const [externalTeamData, setExternalTeamData] = useState([]);
  const [defaultTeamOptions, setDefaultTeamOptions] = useState([]);
  const [externalTeamCount, setExternalTeamCount] = useState(0);
  const [ExternalTeamPage, setExternalTeamPage] = useState(0);
  const [itemCount, setItemCount] = useState(0);
  const [selectTeam, setSelectTeam] = useState(null);
  const [searchParams, setSearchParams] = useSearchParams();
  const [openBottomSheet, setOpenBottomSheet] = useState(false);
  const [tabvalue, setTabValue] = useState(0);

  //set default query terms
  const [query, setQuery] = useState("");

  const teamRowperPage = 50;

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  const handleAdVisible = (adId) => {
    setVisibleAds((prevVisibleAds) => [...prevVisibleAds, adId]);
  };

  useEffect(() => {
    if (ReduxAdsData) {
      let pageheaderAds = ReduxAdsData?.filter((item) => item?.page_id === 11);
      setPageHeaderData(pageheaderAds);
    }
  }, [ReduxAdsData]);

  const fetchPageHeaderAds = (height, placeholder) => {
    if (PageHeaderData?.length > 0) {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={PageHeaderData?.[0]}
          height={height}
          margin={"0px"}
          onAdVisible={handleAdVisible}
          position={1}
        />
      );
    } else {
      return (
        <AdBannner
          placeholder={placeholder}
          addetails={[]}
          height={height}
          margin={"0px"}
        />
      );
    }
  };

  // useEffect(() => {
  //   if (teamId && !selectTeam && defaultTeamOptions?.length > 0) {
  //     const numberTeamId = +teamId;
  //     const team = defaultTeamOptions?.find(
  //       (item) => item?.value === numberTeamId,
  //     );
  //     setSelectTeam(team?.value);
  //   }
  // }, [teamId, defaultTeamOptions]);

  //  with Tournament ids

  useEffect(() => {
    setDefaultTeamOptions([]);
    setSelectTeam(null);
    fetchAllTeam(0);
  }, [touranamentId]);

  // useEffect(() => {
  //   setDefaultTeamOptions([]);
  //   setSelectTeam(null);
  //   fetchAllTeam(0);
  // }, []);

  const fetchAllTeam = async (page) => {
    const passApi = `public/v2/team?SportId=${params?.sportsId}&limit=${teamRowperPage}&offset=${page}&tournamentId=${touranamentId === "0" ? "" : touranamentId}`;
    // const passApi = `public/v2/team?SportId=${params?.sportsId}&limit=${teamRowperPage}&offset=${page}&tournamentId=`;

    const { status, data } = await axiosInstance.get(passApi);
    if (status === 200) {
      let count = data?.result?.count / teamRowperPage;
      const ALL_TEAM = {
        label: "All Teams",
        value: 0,
      };

      let teamList = data?.result?.rows?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

      // Check if ALL_TEAM is not already in the list before adding
      const newTeamList = teamList?.some(
        (team) => team?.value === ALL_TEAM?.value,
      )
        ? teamList
        : [ALL_TEAM, ...teamList];

      let finalData = _.uniqBy(newTeamList, function (e) {
        return e.value;
      });
      setDefaultTeamOptions((prevData) => {
        // Remove any existing ALL_TEAM entries and then add the new list
        const filteredPrevData = prevData.filter(
          (team) => team?.value !== ALL_TEAM?.value,
        );
        return [...filteredPrevData, ...finalData];
      });
      setExternalTeamCount(Math.ceil(count));
      setItemCount(data?.result?.count);
    }
  };

  const fetchAllTeamLoadOptions = async (page) => {
    const passApi = `public/v2/team?SportId=${params?.sportsId}&limit=${teamRowperPage}&search=${query}&offset=${page}&tournamentId=${touranamentId === "0" ? "" : touranamentId}`;
    const { status, data } = await axiosInstance.get(passApi);
    if (status === 200) {
      let newdata = data?.result?.rows?.map((item) => ({
        label: item?.name,
        value: item?.id,
      }));

      // if (teamName) {
      //   setDefaultTeamOptions((preveData) => {
      //     return [...preveData, ...newdata];
      //   });
      // }

      return newdata;
    }
  };

  const handleOnScrollBottomExternalTeam = (e) => {
    if (
      externalTeamCount !== Math.ceil(ExternalTeamPage / teamRowperPage) &&
      itemCount >= teamRowperPage
    ) {
      fetchAllTeam(ExternalTeamPage + teamRowperPage);
      setExternalTeamPage(ExternalTeamPage + teamRowperPage);
    }
  };

  const handleOpenBottomSheet = () => {
    setOpenBottomSheet(true);
  };

  const togglehandleOpenBottomSheet = () => {
    setOpenBottomSheet(false);
  };

  const cricketTabData = [
    {
      id: 0,
      name: "Icc Rankings",
    },
    {
      id: 1,
      name: "table",
    },
    {
      id: 2,
      name: "Fixtures & Results",
    },
  ];

  const handleTabChange = (event, value) => {
    setTabValue(value);
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  // useEffect(() => {
  //   if (teamId) {
  //     setSelectTeam(+teamId);
  //   }

  //   if (teamName) {
  //     setQuery(teamName);
  //     if (query) {
  //       fetchAllTeamLoadOptions(0);
  //     }
  //   }
  // }, [teamName, query]);

  const handleViewExpertTips = () => {
    if (handleRestrictedUser()) {
      navigate(`/teamsports/${params?.sportsTypeName}/sport-expert-tips`);
    } else {
      dispatch(fetchRestrictedRoute(true));
      localStorage.setItem(
        "prev_authroute",
        `/teamsports/${params?.sportsTypeName}/sport-expert-tips`,
      );
    }
  };

  return (
    <>
      <Box className="content-wrap">
        <Box className="banner-img-fix">
          {fetchPageHeaderAds(
            "218px",
            Config.release === "IN" ? pageHeaderIndia : banner,
          )}
        </Box>
        <Box className="bredcrumn-wrap">
          <Breadcrumbs
            separator="/"
            aria-label="breadcrumb"
            className="breadcrumb"
          >
            <Link underline="hover" color="inherit" to="/">
              {localesData?.MENU?.HOME}
            </Link>
            <Link
              underline="hover"
              color="inherit"
              to=""
              className="cursor-default"
            >
              {localesData?.MENU?.SPORTS}
            </Link>
            <Typography>{params?.sportsTypeName}</Typography>
          </Breadcrumbs>
        </Box>
        <Box className="title-btn-box">
          <Typography variant="h1">
            {params?.sportsTypeName === "cricket"
              ? "Cricket"
              : params?.sportsTypeName === "rugbyleague"
                ? "Rugby League"
                : params?.sportsTypeName === "rugbyunion"
                  ? "Rugby Union"
                  : params?.sportsTypeName === "basketball"
                    ? "Basketball "
                    : params?.sportsTypeName === "americanfootball"
                      ? "American Football"
                      : params?.sportsTypeName === "australianrules"
                        ? "Australian Rules "
                        : params?.sportsTypeName === "golf"
                          ? "Golf"
                          : params?.sportsTypeName === "tennis"
                            ? "Tennis"
                            : params?.sportsTypeName === "baseball"
                              ? "Baseball"
                              : params?.sportsTypeName === "icehockey"
                                ? "Ice Hockey"
                                : params?.sportsTypeName === "boxing"
                                  ? "Boxing"
                                  : params?.sportsTypeName === "mma"
                                    ? "mma"
                                    : params?.sportsTypeName === "soccer"
                                      ? "soccer"
                                      : "Rugby Union Sevens"}
          </Typography>
        </Box>
        <Box className="homepage-landing-page-wrap">
          <Box className="homepage-landing-header">
            <Box className="team-select-wrap">
              <AsyncSelect
                cacheOptions
                className={`React team-selectbox-wrap`}
                loadOptions={fetchAllTeamLoadOptions}
                onInputChange={(value) => {
                  // const url = new URL(window.location);
                  // url.searchParams.delete("team_name");
                  // window.history.pushState({}, "", url);
                  setQuery(value);
                }}
                defaultOptions={defaultTeamOptions}
                onMenuScrollToBottom={handleOnScrollBottomExternalTeam}
                classNamePrefix="select"
                placeholder="All Teams"
                components={{ DropdownIndicator }}
                value={
                  defaultTeamOptions?.find((item) => {
                    return item?.value === selectTeam;
                  }) || null
                }
                onChange={(value) => {
                  setSelectTeam(value?.value);
                  setSearchParams({
                    menu: searchParams.get("menu"),
                    touranamentId: searchParams.get("touranamentId"),
                    teamsId: value?.value,
                    // team_name: value?.label,
                  });
                }}
              />
            </Box>
            <Box className="w-full">
              {(params?.sportsTypeName === "cricket" ||
                params?.sportsTypeName === "rugbyleague" ||
                params?.sportsTypeName === "australianrules") && (
                <Button
                  disableElevation
                  disableFocusRipple
                  disableRipple
                  className="exprt-tips-btn"
                  onClick={() => {
                    handleViewExpertTips();
                  }}
                >
                  <Box className="exprt-btn-wrap">
                    <img
                      src={WhiteBulb}
                      alt="white bulb"
                      className="white-bulb-img"
                    />
                    <span className="btn-txt">View Expert Tips</span>
                  </Box>
                </Button>
              )}
            </Box>
          </Box>
          <Box className="homepage-landing-content">
            <Box className="homepage-left-side">
              <HomeLandingLeft selectTeam={selectTeam} />
            </Box>
            {screenWidth > 1080 && (
              <Box className="homepage-right-side">
                <HomeLandingRight />
              </Box>
            )}
          </Box>
        </Box>
        <OurPartner />
        {screenWidth <= 1080 && (
          <Box
            className="bottom-sheet-wrap"
            onClick={() => handleOpenBottomSheet()}
          >
            <img src={BottomSheet} alt="icon" />
          </Box>
        )}
        <SwipeableDrawer
          anchor="bottom"
          open={openBottomSheet}
          onClose={togglehandleOpenBottomSheet}
          onOpen={openBottomSheet}
          className="homepage-bottom-sheet"
        >
          <Box
            role="presentation"
            // onClick={togglehandleOpenBottomSheet}
            // onKeyDown={togglehandleOpenBottomSheet}
          >
            <Box className="bottom-sheet-content-wrap">
              <Box className="bottom-sheet-top-content">
                <Box
                  className="bottom-sheet-img-wrap"
                  onClick={togglehandleOpenBottomSheet}
                >
                  <img src={BottomArrowSheet} alt="icon" />
                </Box>
                <Box className="team-sports-tab">
                  <Tabs
                    value={tabvalue}
                    variant="scrollable"
                    scrollButtons={false}
                    indicatorColor="primary"
                    textColor="primary"
                    className="teamsport-tab-detail"
                  >
                    {cricketTabData?.map((item, index) => {
                      return (
                        <Box key={index}>
                          <Tab
                            label={
                              <Box className="tab-label">
                                <span className="label-name">{item?.name}</span>
                              </Box>
                            }
                            value={item?.id}
                            className={item?.id === tabvalue ? "active " : ""}
                            onChange={(event, newValue) =>
                              handleTabChange(event, item?.id)
                            }
                          />
                        </Box>
                      );
                    })}
                  </Tabs>
                </Box>
              </Box>
              <Box>
                {tabvalue === 0 ? (
                  <LandingICCRankingDetails />
                ) : tabvalue === 1 ? (
                  <LandingTableDetails />
                ) : tabvalue === 2 ? (
                  <LandingFixturesResultDetails />
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
        </SwipeableDrawer>
      </Box>
    </>
  );
};

export default HomeLanding;

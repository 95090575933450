import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  ClickAwayListener,
  Collapse,
  FormControl,
  Typography,
} from "@mui/material";
import { useNavigate, useParams } from "react-router";
import { useLocation } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { ReactComponent as CricketIcon } from "../../assets/images/sportHeaderIcon/headerCricketIcon.svg";
import { ReactComponent as CheckBoxChecked } from "src/assets/images/checkBoxChecked.svg";
import { ReactComponent as CheckboxUnChecked } from "src/assets/images/checkboxUnChecked.svg";
import { useSearchParams } from "react-router-dom";
import "./newSportHeader.scss";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import InfiniteScroll from "react-infinite-scroll-component";
import Loader from "src/components/Loader";
import { fetchFromStorage } from "src/helpers/context";

export const headerCricketMenu = [
  {
    id: 1,
    menu: "Home",
    urlValue: "home",
  },
  {
    id: 2,
    menu: "News",
    urlValue: "news",
  },
  {
    id: 3,
    menu: "Fixtures & Results",
    urlValue: "fixtures_results",
  },
  {
    id: 4,
    menu: "Odds Flucs",
    urlValue: "odds_flucs",
  },
  {
    id: 5,
    menu: "Rankings",
    urlValue: "rankings",
  },
  {
    id: 6,
    menu: "Stats",
    urlValue: "stats",
  },
];

const NewSportHeader = () => {
  const params = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const searchParamsMenu = new URLSearchParams(location.search);
  const menuValue = searchParamsMenu.get("menu"); // Extracts the value of "menu"
  const [searchParams, setSearchParams] = useSearchParams();
  const [sportTouranamentMenu, setSportTouranamentMenu] = useState(false);
  const [asDefault, setAsDefault] = useState(false);
  const [sportTouranamentMenuList, setSportTouranamentMenuList] = useState([]);
  const [touranamentApiCount, setTouranamentApiCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const touranamentRowPerPage = 20;
  const [selectedTouranamentId, setSelectedTouranamentId] = useState(0);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [mobileMenu, setMobileMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("resize", function () {
      setScreenWidth(window.innerWidth);
    });
  }, []);

  useEffect(() => {
    if (!menuValue) {
      setSearchParams({ menu: "home" });
      setSearchParams({
        menu: "home",
        touranamentId: 0,
      });
    }
    fetchTouranament(0);
  }, [menuValue]);

  const handleSportTournamentMenu = () => {
    setSportTouranamentMenu(!sportTouranamentMenu);
  };

  const handleAsDefault = (event) => {
    setAsDefault(event.target.checked);
    if (event.target.checked) {
      setSelectedTouranamentId(0);
      if (searchParams.get("menu") === "home") {
        // Only allow `touranamentId` to be set if menu is "home"
        setSearchParams({
          menu: searchParams.get("menu"),
          touranamentId: 0,
        });
      }
    }
  };

  // Touranament API call

  const fetchTouranament = async (page) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/public/tournament?SportId=4&limit=${touranamentRowPerPage}&offset=${page}`,
      );
      if (status === 200) {
        let count = data?.result?.count / 20;

        // Map the data into the desired format
        let newdata = data?.result?.rows?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));

        // Sort alphabetically by label
        newdata?.sort((a, b) => a?.label?.localeCompare(b?.label));

        // Add "All Cricket" to the beginning of the array
        newdata?.unshift({
          label: "All Cricket",
          value: 0,
        });
        setSportTouranamentMenuList(newdata);
        setTouranamentApiCount(count);
      }
    } catch (err) {}
  };

  const handleOnScrollBottomTournament = async (offset) => {
    try {
      const { status, data } = await axiosInstance.get(
        `/public/tournament?SportId=4&limit=${touranamentRowPerPage}&offset=${offset}`,
      );
      if (status === 200) {
        setOffset(offset);
        let newdata = data?.result?.rows?.map((item) => ({
          label: item?.name,
          value: item?.id,
        }));

        // Sort alphabetically by label

        // Sort alphabetically by label
        newdata?.sort((a, b) => a?.label?.localeCompare(b?.label));
        let tournamentdata = [...sportTouranamentMenuList, ...newdata];

        setSportTouranamentMenuList(tournamentdata);
      } else {
      }
    } catch (err) {}
  };

  const mobileMenuOpen = () => {
    setMobileMenu(!mobileMenu);
  };

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  return (
    <>
      <Box
        className={
          handleRestrictedUser()
            ? "new-sport-Header-wrapper"
            : "without-sigup-header new-sport-Header-wrapper"
        }
      >
        <Box className="header-left-section">
          {screenWidth <= 824 ? (
            <ClickAwayListener onClickAway={() => setMobileMenu(false)}>
              <Box>
                <Box
                  className="mobile-down-arrow"
                  onClick={() => mobileMenuOpen()}
                >
                  <KeyboardArrowDownIcon />
                </Box>
                <Collapse
                  in={mobileMenu}
                  timeout="auto"
                  unmountOnExit
                  className="all-mobile-menu-collapse"
                >
                  <Box className="mobile-menu">
                    {headerCricketMenu?.map((item, index) => {
                      return (
                        <>
                          <Box
                            key={index}
                            className={
                              menuValue === item?.urlValue
                                ? "menu-item-wrap active"
                                : "menu-item-wrap"
                            }
                            onClick={() => {
                              if (item?.urlValue === "home") {
                                setSearchParams({
                                  menu: item?.urlValue,
                                  // ...(item?.urlValue === "home" && {
                                  //   touranamentId:
                                  //     searchParams.get("touranamentId") || 0,
                                  // }), // Include `touranamentId` only if menu is "home"
                                });
                              } else {
                                navigate(`/teamsports/cricket/odds/0/false`);
                              }
                            }}
                          >
                            <Typography className="menu-name">
                              {item?.menu}
                            </Typography>
                          </Box>
                        </>
                      );
                    })}
                  </Box>
                </Collapse>
              </Box>
            </ClickAwayListener>
          ) : (
            <></>
          )}
          <Box>
            <CricketIcon />
          </Box>
          <Box className="sport-name-box">
            <Typography className="sport-name">
              {params?.sportsTypeName}
            </Typography>
          </Box>
        </Box>
        <Box className="header-right-section">
          <ClickAwayListener onClickAway={() => setSportTouranamentMenu(false)}>
            <Box className="all-menu-collapse-wrap">
              <Box
                className="all-menu-wrap"
                onClick={() => handleSportTournamentMenu()}
              >
                <Typography className="all-menu-name">
                  All Cricket <KeyboardArrowDownIcon />
                </Typography>
              </Box>
              <Collapse
                in={sportTouranamentMenu}
                timeout="auto"
                unmountOnExit
                className="all-menu-collapse"
              >
                <Box className="tournament-menu-wrap">
                  <Box className="set-default-wrap">
                    <FormControl>
                      <label>
                        <Checkbox
                          icon={<CheckboxUnChecked className="radio-icon" />}
                          checkedIcon={
                            <CheckBoxChecked className="radio-icon" />
                          }
                          name="filter"
                          value={asDefault}
                          onChange={(event) => {
                            handleAsDefault(event);
                            // setSportTouranamentMenu(false);
                          }}
                          checked={asDefault}
                          disableRipple={true}
                        />
                        Set as default
                      </label>
                    </FormControl>
                  </Box>
                  <InfiniteScroll
                    dataLength={sportTouranamentMenuList?.length}
                    next={() =>
                      handleOnScrollBottomTournament(
                        offset + touranamentRowPerPage,
                      )
                    }
                    hasMore={
                      touranamentApiCount !== 0 &&
                      touranamentApiCount !==
                        Math.ceil(offset / touranamentRowPerPage + 1)
                    }
                    loader={
                      <div className="allsport-loader-center ">
                        <Loader />
                      </div>
                    }
                    height={255}
                  >
                    <Box className="tournament-menu-list-wrap">
                      {sportTouranamentMenuList?.map((item, index) => {
                        return (
                          <>
                            <Box
                              key={index}
                              className={
                                "tournament-menu-list-item " +
                                (item?.value === selectedTouranamentId
                                  ? "active"
                                  : "")
                              }
                              onClick={() => {
                                if (searchParams.get("menu") === "home") {
                                  // Only allow `touranamentId` to be set if menu is "home"
                                  setSearchParams({
                                    menu: searchParams.get("menu"),
                                    touranamentId: item?.value,
                                  });
                                  setSelectedTouranamentId(item?.value);
                                  setAsDefault(false);
                                  setSportTouranamentMenu(false);
                                }
                              }}
                            >
                              {item?.label}
                            </Box>
                          </>
                        );
                      })}
                    </Box>
                  </InfiniteScroll>
                </Box>
              </Collapse>
            </Box>
          </ClickAwayListener>
          {screenWidth > 824 ? (
            headerCricketMenu?.map((item, index) => {
              return (
                <>
                  <Box
                    key={index}
                    className={
                      menuValue === item?.urlValue
                        ? "menu-item-wrap active"
                        : "menu-item-wrap"
                    }
                    onClick={() => {
                      if (item?.urlValue === "home") {
                        setSearchParams({
                          menu: item?.urlValue,
                          ...(item?.urlValue === "home" && {
                            touranamentId:
                              searchParams.get("touranamentId") || 0,
                          }), // Include `touranamentId` only if menu is "home"
                        });
                      } else {
                        navigate(`/teamsports/cricket/odds/0/false`);
                      }
                    }}
                  >
                    <Typography className="menu-name">{item?.menu}</Typography>
                  </Box>
                </>
              );
            })
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </>
  );
};

export default NewSportHeader;

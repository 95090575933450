import React, { useEffect, useState } from "react";
import { Box, Dialog, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import SOCSignupHeader from "./socSignupHeader";
import SOCFeatures from "./socFeatures";
import SOCHowItworks from "./socHowItworks";
import OddsComparisons from "./oddsComparisons";
import SOCBronze from "./socBronze";
import SOCBenefits from "./socBenefits";
import SmartBAppNow from "./smartBAppNow";
import SOCTestimonials from "./socTestimonials";
import OurPartner from "../ourPartners";
import "./socSignupPageV2.scss";
import MembershipPage from "../membershipPage";
import PlanDetails from "../membershipPage/planDetails";
import MembershipFromDetails from "../membershipPage/membershipForm";
import { fetchFromStorage } from "src/helpers/context";
import AgeVerificationPopup from "../UI/ageVerificationPOP";

const SOCSignupPageV2 = () => {
  const [socMembershipModal, setSOCMembershipModal] = useState(false);

  // This set is age verfication popup
  const [ageVerificationPopup, setAgeVerificationPop] = useState(false);
  const isAgeVerified = sessionStorage.getItem("isAgeVerified");

  const handleRestrictedUser = () => {
    const localAuth = fetchFromStorage("auth_token");
    let isLogin = localAuth ? true : false;
    return isLogin;
  };

  useEffect(() => {
    if (!handleRestrictedUser() && !isAgeVerified) {
      setAgeVerificationPop(true);
    }
  }, [ageVerificationPopup]);

  const handleSOCMemberModalOpen = () => {
    setSOCMembershipModal(true);
  };

  const handleSOCMemberModalClose = () => {
    setSOCMembershipModal(false);
  };
  return (
    <>
      <Box className="soc-signup-section-wrap">
        <SOCSignupHeader />
        <SOCFeatures handleSOCMemberModalOpen={handleSOCMemberModalOpen} />
        <SOCHowItworks />
        <OddsComparisons handleSOCMemberModalOpen={handleSOCMemberModalOpen} />
        <SOCBronze />
        <SOCBenefits handleSOCMemberModalOpen={handleSOCMemberModalOpen} />
        <SmartBAppNow />
        <SOCTestimonials />
        <OurPartner />
      </Box>

      {/* Age verfication Modal Code */}

      <Dialog
        aria-labelledby="customized-dialog-title"
        open={ageVerificationPopup}
        className="age-verfication-modal"
      >
        <DialogContent className="age-verfication-modal-details">
          <AgeVerificationPopup setAgeVerificationPop={setAgeVerificationPop} />
        </DialogContent>
      </Dialog>

      <Dialog
        onClose={() => handleSOCMemberModalClose()}
        aria-labelledby="customized-dialog-title"
        open={socMembershipModal}
        className="SOC-member-modal"
      >
        <IconButton
          aria-label="close"
          onClick={() => handleSOCMemberModalClose()}
          className="close-icon"
          size="large"
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className="SOC-member-modal-details">
          <Box className="member-details-wrap">
            <PlanDetails />
            <MembershipFromDetails />
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default SOCSignupPageV2;

import {
  AUTH_USER,
  SET_UPCOMING_RACE,
  SET_INTERVAL,
  ADVERTISING_SCREEN_DATA,
  ADS_DATA,
  NEWS_RELATED,
  AF_DATA,
  AR_DATA,
  BASEBALL_DATA,
  BASKETBALL_DATA,
  BOXING_DATA,
  CRICKET_DATA,
  GOLF_DATA,
  ICEHOCKEY_DATA,
  MMA_DATA,
  RL_DATA,
  RU_DATA,
  SOCCER_DATA,
  TENNIS_DATA,
  RESTRICTED_ROUTE,
  BET_SLIP_TOGGLE,
  BETSLIP_COUNT,
  BETSLIP_DATA,
  BETSLIP_SHARE,
  SUBSCRIPTION_DATA,
  BOOKKEEPER_DATA,
  RUNNER_DATA,
  PUBLIC_BOOKKEEPER_DATA,
  ALL_SPORT,
  LAYOUT_DATA,
  EDIT_FLUCS_LAYOUT_DATA,
  RESULT_TABLE_DATA_SOC,
  TABLE_HEAD_DATA_SOC,
} from "../constants";

export default function reduxData(state = [], action) {
  switch (action.type) {
    case AUTH_USER:
      return { ...state, authData: action.data };
    case SET_UPCOMING_RACE:
      return Object.assign({}, state, { upcomingRaces: action.data });
    case SET_INTERVAL:
      return { ...state, intervalData: action.data, page: action.page };
    case ADVERTISING_SCREEN_DATA:
      return {
        ...state,
        AdvScreenData: action.data,
        isLoading: action.isLoading,
      };
    case ADS_DATA:
      return {
        ...state,
        AdsData: action.data,
      };
    case NEWS_RELATED:
      return {
        ...state,
        RelatedNewsData: action.data,
      };
    case AF_DATA:
      return {
        ...state,
        AFData: action.data,
      };
    case AR_DATA:
      return {
        ...state,
        ARData: action.data,
      };
    case BASEBALL_DATA:
      return {
        ...state,
        BaseballData: action.data,
      };
    case BASKETBALL_DATA:
      return {
        ...state,
        BasketballData: action.data,
      };
    case BOXING_DATA:
      return {
        ...state,
        BoxingData: action.data,
      };
    case CRICKET_DATA:
      return {
        ...state,
        CricketData: action.data,
      };
    case GOLF_DATA:
      return {
        ...state,
        GolfData: action.data,
      };
    case ICEHOCKEY_DATA:
      return {
        ...state,
        IceHockeyData: action.data,
      };
    case MMA_DATA:
      return {
        ...state,
        MMAData: action.data,
      };
    case RL_DATA:
      return {
        ...state,
        RLData: action.data,
      };
    case RU_DATA:
      return {
        ...state,
        RUData: action.data,
      };
    case SOCCER_DATA:
      return {
        ...state,
        SoccerData: action.data,
      };
    case TENNIS_DATA:
      return {
        ...state,
        TennisData: action.data,
      };
    case RESTRICTED_ROUTE:
      return {
        ...state,
        RestrictedData: action.data,
      };
    case BET_SLIP_TOGGLE:
      return {
        ...state,
        BetSlipToggle: action.data,
      };
    case BETSLIP_COUNT:
      return {
        ...state,
        BetSlipCount: action.data,
      };
    case BETSLIP_DATA:
      return {
        ...state,
        BetSlipData: action.data,
      };
    case BETSLIP_SHARE:
      return {
        ...state,
        BetSlipShare: action.data,
      };
    case SUBSCRIPTION_DATA:
      return {
        ...state,
        SubscripitionData: action.data,
      };
    case BOOKKEEPER_DATA:
      return {
        ...state,
        BookkeeperData: action.data,
      };
    case PUBLIC_BOOKKEEPER_DATA:
      return {
        ...state,
        PublicBookkeeperData: action.data,
      };
    case RUNNER_DATA:
      return {
        ...state,
        RunnerDetailsData: action.data,
      };
    case ALL_SPORT:
      return {
        ...state,
        SportData: action.data,
      };
    case LAYOUT_DATA:
      return {
        ...state,
        LayoutData: action.data,
      };
    case EDIT_FLUCS_LAYOUT_DATA:
      return {
        ...state,
        EditLayoutFlucsData: action.data,
      };
    case RESULT_TABLE_DATA_SOC:
      return {
        ...state,
        resultTabData: action.data,
      };
    case TABLE_HEAD_DATA_SOC:
      return {
        ...state,
        tableHeadData: action.data,
      };
    default:
      return { state: [] };
  }
}

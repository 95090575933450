import React, { useEffect, useRef, useState } from "react";

import { Box, Typography } from "@mui/material";
// import CustomTab from "./CustomTab";
import axiosInstance from "src/helpers/Axios/axiosInstance";
import { ReactComponent as RightArrow } from "src/assets/images/right-arrow.svg";
import axios from "axios";
import CompTable from "src/views/component/Home/HomeComponents/CompTable";
import { useLocation, useNavigate, useParams } from "react-router";
import "../../../../Home/HomeComponents/publicTippingComp.scss";
import "./landingTippingComp.scss";

const LeadingPublicTippingComp = () => {
  const params = useParams();
  const location = useLocation();
  // const navigate = useNavigate();
  const searchParamsMenu = new URLSearchParams(location.search);
  const touranamentId = searchParamsMenu.get("touranamentId");

  const abortControllerRef = useRef(null);
  const [selectedLeagueData, setSelectedLeagueData] = useState([]);
  const [compCount, setCompCount] = useState(0);
  const [isCompLoading, setIsCompLoading] = useState(true);

  // with Tournament Ids

  useEffect(() => {
    // fetchAllLeguaue();
    fetchselectedLeagueApi(params?.sportsId, touranamentId);
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [touranamentId]);

  // useEffect(() => {
  //   // fetchAllLeguaue();
  //   fetchselectedLeagueApi(params?.sportsId);
  //   return () => {
  //     if (abortControllerRef.current) {
  //       abortControllerRef.current.abort();
  //     }
  //   };
  // }, []);

  const fetchselectedLeagueApi = async (SportId, leagueId) => {
    setIsCompLoading(true);
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
    }
    const newAbortController = new AbortController();
    abortControllerRef.current = newAbortController;
    try {
      const { status, data } = await axiosInstance.get(
        `tipping/getAll?SportId=${SportId}&tournamentId=${leagueId === "0" ? "" : leagueId}&privacy=public&limit=5&page=1`,
        // `tipping/getAll?SportId=${SportId}&tournamentId=&privacy=public&limit=5&page=1`,
        {
          signal: newAbortController.signal,
        },
      );
      if (status === 200) {
        setSelectedLeagueData(data?.result);
        setCompCount(data?.count);
        setIsCompLoading(false);
      }
    } catch (error) {
      if (axios.isCancel(error)) {
      } else {
        setIsCompLoading(false);
      }
    }
  };

  // const handleNavigateToPublicComps = () => {
  //   navigate("/tipscompetition/my-comps");
  // };

  return (
    <>
      {selectedLeagueData?.length > 0 ? (
        <Box className="component-wrap landing-home-tipping-comp">
          <Box className="landing-header">
            <Typography variant="h3" className="component-header hander-text">
              Tipping Competition
            </Typography>
            {/* <Typography
              className="view-all-text"
              onClick={() => {
                handleNavigateToPublicComps();
              }}
            >
              View All <RightArrow />
            </Typography> */}
          </Box>
          <CompTable
            tableData={selectedLeagueData}
            isLoading={isCompLoading}
            compCount={compCount}
          />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};

export default LeadingPublicTippingComp;

import React, { useState } from "react";
import { Box, Button, Typography } from "@mui/material";
import AgePOP from "../../../../assets/images/ageVerificationPopup.png";
import { ReactComponent as SmartOddsLogo } from "../../../../assets/images/smartOddsComparsion.svg";
import "./ageVerificationPOP.scss";

const AgeVerificationPopup = ({ setAgeVerificationPop }) => {
  const [ageErrorMessage, setAgeErrorMessage] = useState("");

  const handleErrorMessage = () => {
    setAgeErrorMessage(
      "Sorry, your age does not permit you to enter at this time",
    );
  };

  const handleAgeCheck = () => {
    sessionStorage.setItem("isAgeVerified", "true"); // Store in session storage
    setAgeVerificationPop(false);
  };

  return (
    <>
      <Box className="age-verification-popup-wrap">
        <Box className="left-popup-side">
          <img src={AgePOP} alt="icon" />
        </Box>
        <Box
          className={
            ageErrorMessage
              ? "msg-is-available right-popup-side"
              : "right-popup-side"
          }
        >
          <Box className="logo-section">
            <SmartOddsLogo />
          </Box>
          <Typography variant="h6" className="confirm-text">
            Confirm you are over 18 years old
          </Typography>
          {ageErrorMessage && (
            <Typography variant="h6" className="age-error-msg">
              {ageErrorMessage}
            </Typography>
          )}
          <Box className="age-btn-wrap">
            <Button
              variant="contained"
              className="age-pop-btn yes-btn"
              onClick={() => (ageErrorMessage ? "" : handleAgeCheck())}
            >
              Yes
            </Button>
            <Button
              variant="outlined"
              className="age-pop-btn no-btn"
              onClick={() => handleErrorMessage()}
            >
              No
            </Button>
          </Box>
          <Typography className="agree-text">
            By entering this site you are agreeing to the{" "}
            <span className="link-text">
              <a href="/terms-and-conditions">Terms of Use</a>
            </span>{" "}
            and{" "}
            <span className="link-text">
              <a href="/privacy-policy">Privacy Policy</a>
            </span>
            .
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default AgeVerificationPopup;
